import React, {
  useRef, forwardRef, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, useTheme } from '@material-ui/core';

const MAX_WIDTH = 1164;

const SectionContainer = forwardRef(({
  children,
  header,
  subheader,
  className,
  withBackgroundBox,
  id,
}, ref) => {
  const theme = useTheme();
  const headerBoxRef = useRef();
  const [backgroundHeight, setBackgroundHeight] = useState(0);

  useEffect(() => {
    const onResize = () => withBackgroundBox && headerBoxRef.current?.offsetHeight
      && setBackgroundHeight(headerBoxRef.current.offsetHeight * 1.5);

    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [withBackgroundBox]);

  return (
    <Box id={id} ref={ref} className={className}>
      {!!withBackgroundBox && (
        <Box bgcolor="text.primary" position="absolute" height={backgroundHeight} width="100%" zIndex={-1} />
      )}
      {header && (
        <Box
          px={2.5}
          pt={6}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          ref={headerBoxRef}
        >
          <Box mb={3} style={{ maxWidth: MAX_WIDTH }}>
            <Typography
              variant="h3"
              style={{ color: withBackgroundBox ? '#fff' : theme.palette.primary.main, textAlign: 'center' }}
            >
              {header}
            </Typography>
          </Box>
          {subheader && (
            <Box mb={3} style={{ maxWidth: MAX_WIDTH }}>
              <Typography
                variant="h4"
                style={{ color: withBackgroundBox ? '#fff' : theme.palette.primary.main, textAlign: 'center' }}
              >
                {subheader}
              </Typography>
            </Box>
          )}
        </Box>
      )}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        px={2.5}
        py={4}
        style={{ background: 'transparent' }}
      >
        {children}
      </Box>
    </Box>
  );
});

SectionContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  header: PropTypes.shape({}),
  subheader: PropTypes.shape({}),
  withBackgroundBox: PropTypes.bool,
};

SectionContainer.defaultProps = {
  header: null,
  subheader: null,
  withBackgroundBox: false,
};

export default SectionContainer;
